import { getObjectStore } from './openServiceWorkerDatabase';

const deleteToken = async () => {
  let req;
  try {
    const store = await getObjectStore(process.env.REACT_APP_INDEX_DB_TABLE_TOKEN, 'readwrite');
    req = store.clear();
  } catch {
    return false;
  }
  return new Promise((resolve, reject) => {
    req.onsuccess = (event) => {
      console.log('the tokens have been cleared in the indexedb', event);
      resolve(true);
    };
    req.onerror = (error) => {
      console.error('error to save in indexdb', error);
      reject(error);
    };
  });
};

export default deleteToken;
