import { createGetMethod } from 'raf-core-react/dist/utils/axios/axiosUtils';
import rafCoreReactTranslations from 'raf-core-react/dist/utils/localization/defaultTranslations';
import merge from 'lodash.merge';
import { requestHeaders } from '../config';
import defaultTranslations from './defaultTranslations.json';
import TTranslations from './getTranslations.types';

/**
 * Returns a promise which fetches the translations from the backend
 * @return {Promise} A promise that resolves to the translations in JSON
 */
const getTranslations = async (): Promise<void | TTranslations> => {
  const uri = 'translations';
  return createGetMethod<TTranslations>(uri, { headers: requestHeaders })()
    .then(({ data }) => {
      return merge<TTranslations, TTranslations, TTranslations>(
        (rafCoreReactTranslations as { en: TTranslations }).en,
        data,
        defaultTranslations.en
      );
    })
    .catch((err) => {
      console.error(err, 'Failed to fetch translations', uri);
      throw new Error(err);
    });
};

export default getTranslations;
