import React, { lazy, memo, Suspense } from 'react';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { KeycloakInstance } from 'keycloak-js/dist/keycloak';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import axios from 'axios';
import Spinner from 'ebo-react-component-library/dist/components/output/Spinner/Spinner';
import { setTranslation } from 'raf-core-react/dist/utils/localization/Translations';
import './App.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';

import { useAsync } from 'react-use';
import getTranslations from '../api/translations/getTranslations';
import defaultTranslations from '../api/translations/defaultTranslations.json';
import saveToken from '../../serviceworker/saveToken';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
library.add(fas);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
library.add(far);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
library.add(fal);

/**
 * @typedef OmpInspectConfiguration
 *
 * For production, we can configure some properties "at runtime".
 *
 * <p>This enables us to build only one image and deploy it where needed.
 * Once deployed, we can configure customer specific settings. Even
 * without restarting the container.
 *
 * @property REACT_APP_KEYCLOAK_AUTH_URL The base URI used to do request to keycloak.
 */

const authorizationLabel = defaultTranslations.en.application.initAuth;
const isDevelopment = process.env.NODE_ENV === 'development';
const keyCloakUrl: string = !isDevelopment
  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    (ompInspectExternalConfiguration?.REACT_APP_KEYCLOAK_AUTH_URL as string)
  : process.env.REACT_APP_KEYCLOAK_URL;

const initOptions = {
  onLoad: 'login-required',
};

/**
 * Set the token of the event as Authorization header.
 */
const handleReloadTokens = (instance: KeycloakInstance) => {
  let token: string;
  if (process.env.REACT_APP_CREDENTIALS_ENCODED) {
    token = `Basic ${process.env.REACT_APP_CREDENTIALS_ENCODED}`;
  } else if (instance) {
    token = `Bearer ${instance.token}`;
  }
  if (axios.defaults.headers.Authorization !== token) {
    saveToken(token)
      .then(() => {
        console.log('saving the token in the indexDb');
      })
      .catch((e) => {
        console.log('Was not possible to save the token in database', e);
      });
    axios.defaults.headers.Authorization = token;
  }
};

/**
 * A lazy loading component. Render this within a {@link Suspense}.
 *
 * <p>This component is lazy because we need to fetch the translations after authenticating. Most of the translations are defined in the back end.
 *
 * <p>The {@link SecuredApp} uses these in some static constants. If we were to import the app
 * the regular way, these "translate" functions will be fired and error when missing.
 */
const LazySecuredApp = lazy(async () =>
  getTranslations()
    .then((translations) => {
      setTranslation(translations as object);
    })
    .then(async () => import('./securedApp/SecuredApp'))
);

const Loader = memo(() => (
  <div className="app-loading" data-cy="app-loading">
    <Spinner />
    <p className="app-loading__message">{authorizationLabel}</p>
  </div>
));

const SuspensedApp = memo(() => {
  handleReloadTokens(undefined);
  return (
    <Suspense fallback={<Loader />}>
      <LazySecuredApp />
    </Suspense>
  );
});

const App = memo(() => {
  const { keycloak } = useKeycloak();

  // This is only fired at the first render. The hook doesn't trigger a rerender
  // when retrieving a new token. Which is what we want!
  handleReloadTokens(keycloak);

  if (!keycloak.authenticated) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <LazySecuredApp />
    </Suspense>
  );
});

const KeyCloakWrappedApp = () => {
  const { value: authClient, loading } = useAsync(async () => {
    const client = Keycloak({
      realm: process.env.REACT_APP_KEYCLOAK_REALM,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      url: keyCloakUrl,
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
    });
    let tokenParsed: KeycloakTokenParsed;
    // This hack is needed to trigger the refresh token before expiring of the access token
    // FIXME https://github.com/keycloak/keycloak/issues/16746
    if (client) {
      Object.defineProperty(client, 'tokenParsed', {
        set(v: KeycloakTokenParsed) {
          const now = new Date();
          const diff = Math.ceil((v.exp - now.getTime() / 1000) * 0.9);
          now.setSeconds(now.getSeconds() + diff);
          // eslint-disable-next-line no-param-reassign
          v.exp = Math.ceil(now.getTime() / 1000);
          tokenParsed = v;
        },
        get(): KeycloakTokenParsed {
          return tokenParsed;
        },
      });
    }
    return client;
  }, []);

  if (loading) {
    return null;
  }

  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={initOptions}
      onTokens={handleReloadTokens}
    >
      <App />
    </ReactKeycloakProvider>
  );
};

const BasicApp = () => {
  return process.env.REACT_APP_CREDENTIALS_ENCODED || !navigator.onLine ? (
    <SuspensedApp />
  ) : (
    <KeyCloakWrappedApp />
  );
};

export default memo(BasicApp);
