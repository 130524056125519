import TPostRequest from './types/TPostRequest';

/**
 * create a service worker database with name omp-inspect-offline-data
 */
const openServiceWorkerDatabase = async (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const indexedDBOpenRequest = indexedDB.open(process.env.REACT_APP_INDEX_DB);

    indexedDBOpenRequest.onerror = (error) => {
      // error creating db
      console.error('IndexedDB error:', error);
      reject(error);
    };

    // eslint-disable-next-line func-names
    indexedDBOpenRequest.onupgradeneeded = function () {
      // This should only executes if there's a need to
      // create/update db.
      this.result.createObjectStore(process.env.REACT_APP_INDEX_DB_TABLE_TOKEN, {
        autoIncrement: true,
        keyPath: 'id',
      });

      // This should only executes if there's a need to
      // create/update db.
      const objectStore = this.result.createObjectStore(process.env.REACT_APP_INDEX_DB_TABLE, {
        autoIncrement: true,
        keyPath: 'id',
      });
      // we want to create an index on path, so we can easily search for the open request for a specific entity
      objectStore.createIndex('path', 'path', { unique: false });
    };

    // This will execute each time the database is opened.
    // eslint-disable-next-line func-names
    indexedDBOpenRequest.onsuccess = function () {
      resolve(this.result);
    };
  });
};

export default openServiceWorkerDatabase;

export const getObjectStore = async (
  storeName: string,
  mode: IDBTransactionMode
): Promise<IDBObjectStore> => {
  // retrieve our object store
  const db = await openServiceWorkerDatabase();
  return db?.transaction(storeName, mode).objectStore(storeName);
};

export const getOpenRequestByPath = async ({
  storeName,
  mode,
  primaryKey,
}: {
  storeName: string;
  mode: IDBTransactionMode;
  primaryKey: string;
}): Promise<[]> => {
  let req;
  try {
    const store = await getObjectStore(storeName, mode);
    const index = store?.index('path');
    req = index.getAll(primaryKey);
  } catch {
    return [];
  }
  return new Promise((resolve, reject) => {
    if (req) {
      req.onsuccess = (event) => {
        resolve(event.target.result);
      };
      req.onerror = (event) => {
        reject(event);
      };
    }
  });
};

export const getAllOpenRequest = async ({
  storeName,
  mode,
}: {
  storeName: string;
  mode: IDBTransactionMode;
}): Promise<TPostRequest[]> => {
  let req;
  try {
    const store = await getObjectStore(storeName, mode);
    req = store?.getAll();
  } catch {
    return [];
  }

  return new Promise((resolve) => {
    if (req) {
      req.onsuccess = (event) => {
        resolve(event.target.result);
      };
      req.onerror = () => {
        resolve([]);
      };
    }
  });
};
