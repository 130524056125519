import { getObjectStore } from './openServiceWorkerDatabase';
import deleteToken from './deleteToken';

const saveToken = async (token: string): Promise<boolean> => {
  // first we want to delete the old token;
  await deleteToken();
  let req;
  try {
    const store = await getObjectStore(process.env.REACT_APP_INDEX_DB_TABLE_TOKEN, 'readwrite');
    req = store.add({ token });
  } catch {
    return true;
  }
  return new Promise((resolve, reject) => {
    req.onsuccess = (event) => {
      console.log('a new token has been added to indexedb', event);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line no-restricted-globals,@typescript-eslint/no-unsafe-call
      ((self as unknown) as ServiceWorkerGlobalScope).serviceWorker?.postMessage(
        'sendPostRequestToServer'
      );
      resolve(true);
    };
    req.onerror = (error) => {
      console.error('error to save in indexdb', error);
      reject(error);
    };
  });
};

export default saveToken;
